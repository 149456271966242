<template>
  <plc-page :slide="false" :pic-url="banner">
    <div class="fixed-width page-content page-about" :style="{paddingBottom: currentMenu === 2 ? '0' : ''}">
      <div class="submenu-wrapper">
        <div @click="changeMenu(index)" v-for="(item, index) in menu" :key="index" class="item item-4" :class="{active: item.active}">
          <div class="submenu-name">{{item.name}}</div>
          <img v-if="item.active" class="submenu-arrow" src="../assets/images/arrow-down-white.png">
          <img v-else class="submenu-arrow" src="../assets/images/arrow-down-black.png">
        </div>
<!--        <div class="item item-4">-->
<!--          <div class="from">Since</div>-->
<!--          <div class="when">2014</div>-->
<!--        </div>-->
      </div>
      <div class="description" v-if="currentMenu === 0">
        <p>江苏普立兹智能系统有限公司（以下简称普立兹智能）由一群优秀的通讯行业技术人才和经验丰富的农牧行业工作者组成，是一家集研发、设计、生产、销售、服务为一体的综合性科技公司，以“诚信专注、追求卓越、胸怀天下”的核心价值，为用户提供全方位的服务，打造高品质的产品，解决最实在的问题。</p>
        <p>普立兹智能作为专业的人工智能物联网专家，运用“AI人工智能、物联网、移动互联网、云计算”等先进技术，专为畜禽领域量身开发智慧养殖整体解决方案，帮助广大养殖业者改善其工作及生活品质。目前，普立兹智能已拥有非常成熟的“易（e）▪ 养”智能养殖平台，提供两技术一服务：运用AI人工智能和IOT物联网两大技术，针对养殖场各种场景创新出多个解决方案，包括物联网智能环控，AI人工智能母猪测温、AI人工智能盘猪估重、智能电子饲喂、水电用量监测、料塔称重等系统，通过SaaS云平台服务，将环境、动物个体、饲喂、用水量、用电量等数据自动推送给用户进行高效分析和管理，打造真正的智慧养殖场，为用户提供更全面更智能的管理方案，提升养殖成绩，提高效率，降低成本。</p>
        <p>普立兹智能努力以科技和信息为载体，将农业与物联网结合，以技术推动产业升级，以信息化带动产业转型，让农渔民能够享受到“物联网+”带来的好处，推动现代化农业的发展。</p>
      </div>
      <div class="description" v-else-if="currentMenu === 1">
        <p>诚信实在专注本业：普立兹实实在在，专注本业，正派经营，诚信感恩，绝不投机；</p>
        <p>创新发展追求卓越：技术创新超前市场需求及变化，开放沟通追求顾客价值最大化，鼓励有价值的竞争，追求卓越；</p>
        <p>胸怀中国放眼天下：普立兹的系统产品与品牌经营均以中国第一为努力目标，更以世界第一梯队的优秀地位为奋斗目标。</p>
      </div>
      <div v-else-if="currentMenu === 2">
        <div class="honor-wrapper">
          <div v-for="item in honor" :key="item.desc" class="honor-item">
            <div class="honor-pic-wrapper">
              <img class="honor-pic" :class="{'vertical': item.vertical, 'horizontal': !item.vertical}" :src="item.picUrl">
            </div>
            <div class="honor-desc" v-html="item.desc"></div>
          </div>
        </div>
      </div>
      <div class="description" v-else>
<!--        <div class="development">-->

<!--        </div>-->
        <img class="development" src="../assets/images/development.png">
      </div>
    </div>
  </plc-page>
</template>

<script type="text/ecmascript-6">
  import PlcPage from '../components/page/Index'
  import { HONOR } from '../util/constants'

  export default {
    name: 'About',
    components: {
      PlcPage
    },
    data () {
      return {
        banner: require('../assets/images/banner/banner-about.jpg'),
        menu: [{
          name: '公司简介',
          active: true
        }, {
          name: '经营理念',
          active: false
        }, {
          name: '所获荣誉',
          active: false
        }, {
          name: '发展历程',
          active: false
        }],
        currentMenu: 0,
        honor: HONOR
      }
    },
    created () {
      const menu = Number(localStorage.getItem('menu'))
      if (!isNaN(menu)) {
        this.changeMenu(menu)
        localStorage.removeItem('menu')
      }
    },
    methods: {
      changeMenu(index) {
        this.menu.forEach((item, idx) => {
          item.active = false
          if (idx === index) {
            item.active = true
          }
        })
        this.currentMenu = index
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .page-about
    .description > p
      text-indent 2em
      line-height 2
      font-size 16px
      color #585555
    .honor-wrapper
      display flex
      flex-wrap wrap
      align-items center
      justify-content space-between
      .honor-item
        width 280px
        box-sizing border-box
        margin-bottom 65px
        .honor-pic-wrapper
          height 260px
          display flex
          align-items center
          justify-content center
          background linear-gradient(#f6f6f6, #dddddd)
          .honor-pic
            -webkit-box-reflect below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent) , to(rgba(250, 250, 250, 0.4)))
            &.vertical
              height 163px
            &.horizontal
              width 183px
        .honor-desc
          display flex
          align-items center
          justify-content center
          line-height 1.3
          height 75px
          font-size 13px
          font-weight 600
          text-align center
          color #141010
          background-color #efefef

    .development
      width 1200px
</style>
